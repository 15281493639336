<template>
  <div class="mx-4">
    <div class="bg-mask"></div>
    <div class="relative m-auto z-10">
      <div class="back-btn-wrapper">
        <button class="back-btn" v-if="this.$route.name !== 'successRequest'"  @click="()=> this.$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
            <defs>
              <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
              </filter>
            </defs>
            <g id="Group_12962" data-name="Group 12962" transform="translate(-308 -28)">
              <g id="Group_4717" data-name="Group 4717" transform="translate(317 33.547)">
                <g id="Group_4426" data-name="Group 4426" transform="translate(0 0.452)">
                  <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_3158)">
                    <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)" fill="#ff7400"/>
                  </g>
                </g>
              </g>
              <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(317.705 34.376)" fill="#fff"/>
            </g>
          </svg>
        </button>
      </div>
      <div class="header-search-page mt-8">
        <p class="text-center">انتخاب آدرس</p>
      </div>
      <div class="search__wrapper">
        <span class="svg-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.686" height="19.729" viewBox="0 0 19.686 19.729">
            <path id="Union_1" data-name="Union 1" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(0.5 0.5)" fill="none" stroke="#ff7400" stroke-width="1"/>
          </svg>
        </span>
        <input type="text" @input="filterRealEstate"  placeholder="بنگاه مورد نظر خود را وارد کنید">
        <span class="selectCity" @click="isShowCities = !isShowCities">{{ selectedCity }}</span>
      </div>
      <div class="my-location-city" @click="getNowLocation">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 16.5 16.5">
          <path id="Icon_material-my-location" data-name="Icon material-my-location" d="M9.75,6.75a3,3,0,1,0,3,3A3,3,0,0,0,9.75,6.75ZM16.455,9A6.746,6.746,0,0,0,10.5,3.045V1.5H9V3.045A6.746,6.746,0,0,0,3.045,9H1.5v1.5H3.045A6.746,6.746,0,0,0,9,16.455V18h1.5V16.455A6.746,6.746,0,0,0,16.455,10.5H18V9Zm-6.7,6A5.25,5.25,0,1,1,15,9.75,5.246,5.246,0,0,1,9.75,15Z" transform="translate(-1.5 -1.5)" fill="#fff"/>
        </svg>
        موقعیت فعلی من
      </div>
      <template v-if="isShowCities">
        <div class="city-search">
          <input v-model="filter" type="text" placeholder="شهر مورد نظرتو جستجو کن">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.686" height="19.729" viewBox="0 0 19.686 19.729">
            <path id="Union_3" data-name="Union 3" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(0.5 0.5)" fill="none" stroke="#afafaf" stroke-width="1"/>
          </svg>
        </div>
        <div class="list-city overflow-y-scroll scrollbar-hide">
          <p>نتایج جستجو</p>
          <ul>
            <li v-for="city in filterCities" :key="city.id" class="items" @click="handleSelectCity(city)">
              <h6>{{ city.name }}</h6>
              <span>{{city.province.name}}</span>
            </li>
          </ul>
        </div>
      </template>
      <div class="list-city overflow-y-scroll scrollbar-hide h-screen" id="list-area" v-if="isShowArea">
        <ul v-if="realEstates.length > 0" class="mb-20">
          <li v-for="(realEstate , i) in realEstates" :key="i" class="items flex flex-row-reverse gap-x-4" @click="handleSelecteRealEstate(realEstate)" >
            <div class="flex flex-row-reverse gap-4 w-full" style="justify-content: start">
              <div>
                <h6>{{ realEstate.name }}</h6>
                <span>{{realEstate.city + ',' + realEstate.area_name + ',' + realEstate.address }}</span>
              </div>
              <img class="w-9 h-9 rounded-full" :src="baseURL + realEstate.logo" alt="">
            </div>
          </li>
        </ul>
        <div class="text-center text-white" v-else>{{textArea}}</div>
      </div>
      <div class="text-center text-white mt-4" v-else>لطفا اول شهر را خود را انتخاب کنید</div>
    </div>

  </div>

</template>

<script>
import axios from '../../plugins/axios'
import axiosMap from '../../plugins/mapAxios'
export default {
  name: "SelectRealEstatesRealEstate",
  components: {

  },
  data() {
    return {
      cities : null,
      isShowCities : false,
      filter : '',
      isShowArea : false,
      selectedCity : 'انتخاب شهر',
      realEstates : [],
      textArea : 'بنگاه مورد نظر خود را جستجو کنید',
      selectedRealEstates : [],
      timer : null,
      baseURL : this.$store.state.general.baseURL
    }
  },
  methods:{
    handleSelectCity(data){
      this.selectedCity = data.name;
      this.isShowCities = false;
      this.isShowArea = true
      this.selectedRealEstates = [];
      axios.post('/real-estates/filter',{
        filter :{
          city : {
            operator: '=',
            value : this.selectedCity
          }
        }
      }).then(({data})=>{
        this.realEstates = data.data.data
      })
    },
    filterRealEstate(e) {
      if(this.timer){
        clearTimeout(this.timer);
        this.timer = null
      }
      this.timer = setTimeout(async ()=>{
        if (this.isShowArea) {
          await axios.post('/real-estates/filter',{
            filter :{
              name : {
                operator : 'LIKE',
                value : '%'+ e.target.value + '%'
              },
              city : {
                operator: '=',
                value : this.selectedCity
              }
            }
          }).then(async ({data}) => {
            this.realEstates = await data.data.data
            if (this.realEstates.length <= 0) {
              this.textArea = 'بنگاه مورد نظر یافت نشد'
            }
          })
        }
        else if (e.target.value === '') {
          this.realEstates = []
          this.textArea = "لطفا بنگاه خود را جستجو کنید"
        }
      },800)
    },
    handleSelecteRealEstate(data){
      this.$router.push({name : 'RealEstatePageRealEstate' ,params :{id : data.id}})
    },
    getNowLocation() {
      navigator.geolocation.getCurrentPosition(this.success, this.error)
    },
    async success(pos) {
      var crd = pos.coords;
      await axiosMap.get('/fast-reverse/',{
        params:{
          lat : crd.latitude,
          lon : crd.longitude
        }
      }).then(({data})=>{
        let city  = {
          name : data.city
        }
        this.handleSelectCity(city)
      })
    },
    error() {
      this.$toast.error('دسترسی لوکشین را فعال کنید')
    },

  },
  computed : {
    filterCities(){
      return this.cities.filter(item => item.name.includes(this.filter))
    }
  },
  async mounted() {
    await axios.post('options/cities').then(({data})=>{
      this.cities = data.data;
    })

    let city = JSON.parse(localStorage.getItem('city'))
    if ('city' in localStorage){
      this.selectedCity = city.name
      this.isShowArea = true
    }
    axios.post('/real-estates/filter',{
      filter :{
        city : {
          operator: '=',
          value : city.name
        }
      }
    }).then(({data})=>{
      this.realEstates = data.data.data
    })
  }
}
</script>


<style scoped lang="scss">
.search__wrapper{
  input{
    direction: rtl;
    margin-right: 1rem;
  }
  .selectCity{
    white-space: nowrap;
    height: 100%;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    position: relative;
    top: 3px;
    margin-left: 0.5rem;
    cursor: pointer;
    &:after{
      content: '';
      width: 1px;
      background-color: #e4e4e424;
      height: 58px;
      top: -22px;
      position: absolute;
      left: -13px;
    }
  }

}
#list-area  {
  ul{
    .items{
      &:before{
        top: 5px !important;
      }
    }
  }
}
</style>
